/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useState } from 'react'

const styles = {
  countryList: {
    display: 'inline-flex',
    position: 'relative',
    color: '#412dc2'
  },
  countryListItem: {
    cursor: 'pointer',
    lineHeight: '24px',
    marginBottom: '5px',
  },
  countryListActive: {
    display: 'block',
  },
  countryListActiveItem: {
    borderBottom: '1px dotted #412dc2',
    cursor: 'pointer',
    lineHeight: '24px',
    margin: '0px',
    marginBottom: '5px',
  }
}

const CountryListData = [
  'Australia', 'United Kingdom', 'United States'
]

const CountryList = () => {

  const [dropdownIsActive, setDropdownIsActive] = useState(false)
  const [activeCountryList, setActiveCountryList] = useState(CountryListData[0])

  return (
    <div sx={styles.countryList}>
      <p sx={styles.countryListActiveItem} onClick={() => setDropdownIsActive(!dropdownIsActive)}>{activeCountryList}</p>
      <ul sx={{
        display: dropdownIsActive ? 'block' : 'none',
        padding: '10px',
        position: 'absolute',
        listStyle: 'none',
        width: '220px',
        top: '5px',
        zIndex: '10',
        background: "#fffffff2",
        borderRadius: "1rem",
        boxShadow: "rgb(12 12 12 / 25%) 0px 3px 5px",
        left: "-25px"
      }}>
        {CountryListData && CountryListData.map((item, idx) =>
          <li key={idx} sx={styles.countryListItem} onClick={() => {
            setActiveCountryList(CountryListData[idx])
            setDropdownIsActive(!dropdownIsActive)
          }}>{item}</li>
        )}
      </ul>
    </div>
  )
}

export default CountryList
