import React from 'react'
import Helmet from 'react-helmet'
import { helmetJsonLdProp } from 'react-schemaorg'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'
import { useStaticQuery } from 'gatsby'
import { logoQuery } from '@elegantstack/flow-ui-layout/src/Header/Header.Logo'
import { getImage } from 'gatsby-plugin-image'

const Seo = props => {
  const { title, children, isReview = false, seo_title, seo_desc } = props

  const site = useSiteMetadata()

  /**
   * Structured Data (JSON-LD)
   */

  const scripts = []
  const { logo } = useStaticQuery(logoQuery)
  const logoNormal = getImage(logo)

  // // Breadcrumb
  // if (title && category) {
  //   const breadcrumbJsonLd = helmetJsonLdProp({
  //     '@context': 'https://schema.org',
  //     '@type': 'BreadcrumbList',
  //     itemListElement: [
  //       {
  //         '@type': 'ListItem',
  //         position: 1,
  //         name: site.name,
  //         item: siteUrl
  //       },
  //       {
  //         '@type': 'ListItem',
  //         position: 2,
  //         name: category.name,
  //         item: `${siteUrl}${category.slug}`
  //       }
  //     ]
  //   })
  //   scripts.push(breadcrumbJsonLd)
  // }

  const websiteJsonLd = helmetJsonLdProp({
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: site.siteUrl,
    name: site.name
  })

  const organizationJsonLd = helmetJsonLdProp({
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: site.siteUrl,
    name: site.name,
    logo: logoNormal.images.fallback.src
  })

  const socials = site.social.map(social => social.url)
  const newsMediaOrganization = helmetJsonLdProp({
    '@context': 'https://schema.org',
    '@type': 'NewsMediaOrganization',
    name: site.name,
    url: site.siteUrl,
    ContactPoint: {
      '@type': 'ContactPoint',
      contactType: 'customer support',
      url: `${site.siteUrl}/contact`,
      email: site.email,
      contactOption: ['HearingImpairedSupported', 'TollFree'],
      availableLanguage: 'English'
    },
    logo: {
      '@type': 'ImageObject',
      url: logoNormal.images.fallback.src
      // width: 606,
      // height: 160
    },
    sameAs: socials
  })

  scripts.push(newsMediaOrganization)
  scripts.push(organizationJsonLd)
  scripts.push(websiteJsonLd)

  if (isReview) {
    const { logo, title, score, author, pros, cons } = props
    let reviewBody = ''
    if (pros && cons) {
      reviewBody = `Pros: ${pros.join(',')} Cons: ${cons.join(',')}`
    }
    const reviewJsonLd = helmetJsonLdProp({
      '@context': 'https://schema.org/',
      '@type': 'Review',
      itemReviewed: {
        '@type': 'Organization',
        image: logo.SanityImageAsset_logo.images.fallback.src,
        name: title
      },
      reviewRating: {
        '@type': 'Rating',
        ratingValue: score / 2
      },
      name: title,
      author: {
        '@type': 'Person',
        name: author.name
      },
      reviewBody,
      publisher: {
        '@type': 'Organization',
        name: 'EveningStar'
      }
    })
    scripts.push(reviewJsonLd)
  }



  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      title={seo_title ? seo_title : title}
      titleTemplate={`%s | ${site.title}`}
      script={scripts}
    >
      {seo_desc && <meta name='description' content={seo_desc} />}
      {children}
    </Helmet>
  )
}

export default Seo
